import React, { useState } from 'react'
import classes from './Header.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthProvider'
import OpenAndConquerModal from '../../openAndConquer/OpenAndConquerModal'
import { useHomeRef } from '../../../context/RefContext'

const Header = ({ setIsSidebarActive, isSidebarActive, onClose }) => {
  const [openMenu, setOpenMenu] = useState(null)
  const navigate = useNavigate()
  const { auth } = useAuthContext()
  const { homeRef } = useHomeRef()
  const location = useLocation()

  const isPWA = window.matchMedia('(display-mode: standalone)').matches
  const [refresh, setRefresh] = useState(0)

  const handleMenuToggle = (menuIndex) => {
    setOpenMenu((prevMenu) => (prevMenu === menuIndex ? null : menuIndex))
  }

  const handleLogoClick = () => {
    const searchParams = new URLSearchParams(location.search)
    const page = searchParams.get('page')

    if (
      homeRef.current &&
      location.pathname === '/' &&
      (page === '1' || !page)
    ) {
      homeRef.current.scrollIntoView({ behavior: 'smooth' })
    } else {
      navigate('/?page=1')

      if (homeRef.current) {
        homeRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }

    setIsSidebarActive(false)
  }

  const handleRefresh = () => {
    setRefresh(360)
    setTimeout(() => {
      window.location.reload()
    }, 200)
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className={classes.header}>
      <div className={classes.headerInnerWrapper}>
        <div className={classes.headerLeft}>
          <div
            className={`imageWrapper ${classes.logoWrapper}`}
            onClick={() => handleLogoClick()}
          >
            <img src='/logo-new-big.svg' alt='betkotips logo' />
          </div>
          <div className={classes.desktopLinks}>
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(1)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 1 ? classes.activeMenuItem : ''
                }`}
                onClick={() => handleMenuToggle(1)}
              >
                Betko Family
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='Betkogram' />
                </div>
              </button>
              {openMenu === 1 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/betko_family')}>
                    Šta je Betko family?
                  </li>
                  <li onClick={() => navigate('/betko_plus')}>
                    Šta je Betko plus ?
                  </li>
                </ul>
              )}
            </div>
            {/* Betkogram */}
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(2)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 2 ? classes.activeMenuItem : ''
                }`}
                onClick={() => handleMenuToggle(2)}
              >
                Betkogram
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='Betkogram' />
                </div>
              </button>
              {openMenu === 2 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/glavne-kartice')}>
                    Glavne kartice
                  </li>
                  <li onClick={() => navigate('/sportske-price')}>
                    Sportske priče
                  </li>
                  <li onClick={() => navigate('/semafori')}>Semafori</li>
                  <li onClick={() => navigate('/info_blok')}>Info blok</li>
                  <li onClick={() => navigate('/bet_portal')}>Bet portal</li>
                  <li onClick={() => navigate('/statistika')}>Statistika</li>
                  <li onClick={() => navigate('/vasi_predlozi')}>
                    Vaši predlozi
                  </li>
                  <li
                    onClick={() =>
                      navigate('/bet_portal/653e47fa52a098b10de971f2')
                    }
                  >
                    BET
                  </li>
                  <li
                    onClick={() =>
                      navigate('/bet_portal/6770743ea1304b4e5cf5784f')
                    }
                  >
                    STAT
                  </li>
                  <li
                    onClick={() =>
                      navigate('/bet_portal/65611f28f4ca9761eb5249ad')
                    }
                  >
                    NET
                  </li>
                </ul>
              )}
            </div>
            {/* Korisnicki paketi */}

            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(3)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 3 ? classes.activeMenuItem : ''
                }`}
                onClick={() => handleMenuToggle(3)}
              >
                Korisnički paketi
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='Betkogram' />
                </div>
              </button>
              {openMenu === 3 && (
                <ul className={classes.subMenu}>
                  <li
                    // onClick={() =>
                    //   showPopupMessage('Uskoro na BETKOTIP.COM')
                    // }
                    onClick={() => navigate('/bet_kalkulatori')}
                  >
                    BET KALKULATORI
                  </li>
                  <li onClick={() => navigate('/bet_kurs')}> BET KURS</li>
                  <li onClick={() => navigate('/bet_kurs_novo')}>PREMIUM</li>

                  <li onClick={() => navigate('/bet_kurs_tutorijal')}>
                    Šta je Bet kurs ?
                  </li>
                  <li onClick={() => navigate('/bet_kalkulatori_tutorijal')}>
                    Opis BET kalkulatora
                  </li>
                </ul>
              )}
            </div>
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(4)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 4 ? classes.activeMenuItem : ''
                }`}
                onClick={() => navigate('/kupi_paket')}
              >
                Kupi paket{' '}
                <div className='image-wrapper'>
                  {/* <img src='/icons/submenu.svg' alt='kupi paket' /> */}
                </div>
              </button>
              {/* {openMenu === 4 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/korisnicki_paketi')}>
                    Kupi paket
                  </li>
                  <li
                    onClick={() =>
                      navigate('/korisnicki_paketi/65bac125a86ff9a51a6b110f')
                    }
                  >
                    Kupovina putem uplatnice
                  </li>
                </ul>
              )} */}
            </div>

            <button
              className={`${classes.headerMenuBtn}`}
              onClick={() => {
                navigate('/mister_tipster/match')
              }}
            >
              Otvori i osvoji
            </button>
            <OpenAndConquerModal
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
            />
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(5)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 5 ? classes.activeMenuItem : ''
                }`}
              >
                Betko shop
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='betko-shop' />
                </div>
              </button>
              {openMenu === 5 && (
                <ul className={classes.subMenu}>
                  <li
                    onClick={() => {
                      navigate('/betko_shop')
                    }}
                  >
                    Betko Shop
                  </li>
                  <li
                    onClick={() => {
                      navigate('/betko_shop/shop?shopType=shop')
                    }}
                  >
                    Shop
                  </li>
                  <li
                    onClick={() => {
                      navigate('/betko_shop/shop?shopType=auction')
                    }}
                  >
                    Licitacije
                  </li>
                  <li
                    onClick={() => {
                      navigate('/betko_shop/shop?shopType=sport-tourist')
                    }}
                  >
                    Sport Turist
                  </li>
                </ul>
              )}
            </div>

            <button
              className={`${classes.headerMenuBtn}`}
              onClick={() => navigate('/rezultati_uzivo')}
            >
              <div className='image-wrapper'>
                <img src='/icons/dot.svg' alt='Rezulati uzivo' />
              </div>
              Rezultati uživo
            </button>
          </div>
        </div>

        {isPWA && (
          <div className={classes.refreshIcon}>
            <img
              onClick={handleRefresh}
              style={{ transform: `rotate(${refresh}deg)` }}
              src='/icons/refresh.svg'
              alt='refresh'
            />
          </div>
        )}

        <div className={classes.headerRight}>
          <span
            className={classes.headerLink}
            onClick={() => {
              navigate('/18+')
              setIsSidebarActive(false)
            }}
          >
            18+
          </span>
          <div
            className={`${classes.menuItem} ${classes.info}`}
            onMouseEnter={() => setOpenMenu(6)}
            onMouseLeave={() => setOpenMenu(null)}
          >
            <button
              className={`${classes.headerMenuBtn} ${
                openMenu === 6 ? classes.activeMenuItem : ''
              }`}
              onClick={() => handleMenuToggle(6)}
            >
              Info
              <div className='image-wrapper'>
                <img src='/icons/submenu.svg' alt='Info' />
              </div>
            </button>
            {openMenu === 6 && (
              <ul className={classes.subMenu}>
                <li onClick={() => navigate('/o_nama')}>O nama</li>
                <li onClick={() => navigate('/kontakt')}>Kontakt</li>
                <li onClick={() => navigate('/saradnja')}>Saradnja</li>
                <li
                  onClick={() =>
                    navigate('/korisnicki_paketi/65bac1bba86ff9a51a6b1185')
                  }
                >
                  Opšti uslovi
                </li>
                <li onClick={() => navigate('/politika-privatnosti')}>
                  Politika privatnosti
                </li>
                <li>
                  <a
                    href='https://www.facebook.com/share/1GuWdyGKQo/'
                    target='_blank'
                  >
                    <img
                      className={classes.socialMedia}
                      src='/icons/header/facebook.svg'
                      alt='facebook'
                    />
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.instagram.com/betkotipcom_official?igsh=MWM1aHFyYmdtcm1saQ=='
                    target='_blank'
                  >
                    <img
                      className={classes.socialMedia}
                      src='/icons/header/instagram.svg'
                      alt='Instagram'
                    />
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.tiktok.com/@betkotipcom2?_t=ZM-8uU1Uh4axDp&_r=1'
                    target='_blank'
                  >
                    <img
                      className={classes.socialMedia}
                      src='/icons/header/tiktok.svg'
                      alt='tiktok'
                    />
                    TikTok
                  </a>
                </li>
                <li>
                  <a href='https://t.me/+noxLV5bw8eM0MWE0' target='_blank'>
                    <img
                      className={classes.socialMedia}
                      src='/icons/header/telegram.svg'
                      alt='telegram'
                    />
                    Telegram
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.youtube.com/@betkotip-nm4yk/featured'
                    target='_blank'
                  >
                    <img
                      className={classes.socialMedia}
                      src='/icons/header/youtube.svg'
                      alt='youtube'
                    />
                    YouTube
                  </a>
                </li>
              </ul>
            )}
          </div>
          <button
            className={`${classes.startMenuBtn} ${
              isSidebarActive ? classes.activeStartMenuBtn : ''
            }`}
            onClick={() => {
              setIsSidebarActive((curr) => !curr)
            }}
          >
            <div className='image-wrapper'>
              <img
                src={`/icons/start-menu${isSidebarActive ? '-active' : ''}.svg`}
                alt='Start Meni'
              />
            </div>
            Start Meni
          </button>

          {auth && Object.keys(auth).length < 1 ? (
            <button
              onClick={() => navigate('/registracija_i_logovanje')}
              className={classes.authBtn}
            >
              Prijavi se
            </button>
          ) : (
            <button
              onClick={() => navigate('/moj_nalog')}
              className={classes.authBtn}
            >
              Moj nalog
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header

// import React from 'react'
// import classes from './Header.module.css'

// const Header = () => {
//   return (
//     <div className={classes.header}>
//       <div className={classes.headerInnerWrapper}>
//         <div className={classes.headerLeft}>
//           <div className={`imageWrapper ${classes.logoWrapper}`}>
//             <img src='/logo-new-big.svg' alt='betkotips logo' />
//           </div>
//           <div className={classes.desktopLinks}>

//           </div>
//         </div>
//         <div className={classes.headerRight}>
//           <a href='/18+'>18+</a>
//           <button className={classes.startMenuBtn}>
//             <div className='image-wrapper'>
//               <img src='/start-menu-btn.svg' alt='Start Meni' />
//             </div>
//             Start Meni
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Header
